<template>
  <div class="desktopLayoutClass">
    <v-app>
      <div
        v-show="show_header"
        class="header"
        style="
          display: flex;
          flex-direction: row;
          padding-top: 10px;
          padding-left: 5px;
          background-color:white;
        "
      >
        <div style="display: flex; flex-direction: row; width: 100%">
          <div
            style="width: 10%; padding-top: 6px"
            align="left"
            v-on:click="redirectToHomePage()"
          >
            <img
              src="https://img.icons8.com/material-outlined/20/000000/left.png"
            />
          </div>
          <div style="width: 90%" align="left">
            <span style="font-weight: 600; font-size: 16px"
              >{{ $t("Customer.ViewPrescription.View_Prescription") }}</span
            >
          </div>
        </div>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="auth_error_enable" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break: normal">
                 {{$t("Warning_Message.Unauthorized")}}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="closeDialogUnauthorized"
                >
                 {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <v-container>
        <v-card v-if="!isLoadingPresp">
          <div
            style="
              height: 85vh;
              position: relative;
              overflow: scroll;
            "
          >
            <div style="display: flex; flex-direction: column">
              <div class="prescriptionHeader">
                <div style="width:30%; padding:3%; margin-top:8%">
                   <img
                   width="90px"
                    src="https://d3vsnl8idgwrlw.cloudfront.net/mymedicinelogo.svg"
                    />
                </div>
                <div style="margin-left:2%;">
                  <h4 style="text-align: left !important;">My-Medicine</h4>
                  <p class="headerTextClass">HotLine: 09 980635588</p>
                  <p class="headerTextClass">E-mail: care@mymedicine.com.mm</p>
                  <p class="headerTextClass">Website: www.telemedicine.mymedicine.com.mm</p>

                </div>

              </div>
               <div style="border-bottom: double 1px #000000;"></div>
                <div style="border-bottom: double 3px #000000;"></div>
                <table style="text-align: initial;" >
                  <tr>
                    <td style="padding-left:2%; font-weight:bold">
                      {{ $t("Customer.ViewPrescription.Booking_ID") }}
                    </td>
                    <td> :</td>
                    <td style="padding-left:2%">  {{ appointment_reference_number }}</td>
                  </tr>
                  <tr>
                    <td style="padding-left:2%;font-weight:bold">
                     {{ $t("Customer.ViewPrescription.Date") }}
                    </td>
                    <td> :</td>
                    <td style="padding-left:2%">  {{ presc_object.data.date_of_appointment }}</td>
                  </tr>
                  <tr>
                    <td style="padding-left:2%;font-weight:bold">
                    {{ $t("Customer.ViewPrescription.Time") }}
                    </td>
                    <td> :</td>
                    <td style="padding-left:2%">  {{ presc_object.appointment_data.time_slot }}</td>
                  </tr>
                  <tr>
                    <td style="padding-left:2%;font-weight:bold">
                   {{ $t("Customer.ViewPrescription.Name") }}
                    </td>
                    <td> :</td>
                    <td style="padding-left:2%">   {{ presc_object.data.customer_name }}</td>
                  </tr>
                </table>
              <div class="ageGenderClass">
                <div class="prescriptionHeader">
                   <div style="font-weight: normal">{{ $t("Customer.ViewPrescription.Age") }}</div>
                   <div class="valueClass">{{ ageOfPatient }}</div>

                </div>
                <div>|</div>
                <div class="prescriptionHeader">
                   <div style="font-weight: normal">{{ $t("Customer.ViewPrescription.Gender") }}</div>
                   <div class="valueClass" v-if="presc_object.customer_data.customer_gender == 'Female'">F</div>
                   <div class="valueClass" v-else>
                     M
                   </div>
                </div>
                 <div>|</div>
                <div class="prescriptionHeader">
                   <div style="font-weight: normal">{{ $t("Customer.ViewPrescription.Weight") }}</div>
                   <div class="valueClass">{{ presc_object.customer_data.customer_weight }}</div>
                </div>
                 <div>|</div>
                 <div class="prescriptionHeader">
                   <div style="font-weight: normal">{{ $t("Customer.ViewPrescription.Height") }}</div>
                   <div class="valueClass">{{ presc_object.customer_data.customer_height }}</div>
                </div>
              </div>
              <div style="border-bottom:   double 1px #000000;"></div>
              <div class="detailsClass" v-show="false">
                <div >
                 <p class="detailsTitle"> {{ $t("Customer.ViewPrescription.Complaint") }} </p>
                </div>
                <div class="detailsValue">
                  <p>{{ presc_object.appointment_data.description }}</p>
                </div>
              </div>
              <div class="detailsClass" v-show="false">
                <div >
                 <p class="detailsTitle"> {{ $t("Customer.ViewPrescription.Attachment") }} </p>
                </div>
                <div class="attachmentClass">
                 <div v-for="attachments in allAttachments" :key="attachments" style="padding: 5px 15px; margin-bottom: 15px;">
                      <!-- {{attachments}} -->
                      <v-chip target="_blank" @click="ViewFile(attachments)">
                        <!-- {{attachments.split("/")[attachments.split("/").length -1]}} -->
                        {{attachments.split('#@#')[1]}}
                      </v-chip>
                  </div>
                </div>
              </div>
               <div class="detailsClass">
                <div >
                 <p class="detailsTitle"> {{ $t("Customer.ViewPrescription.Presenting_Symptoms") }} </p>
                </div>
                <div class="detailsValue">
                <p> {{ presc_object.data.presentSymptoms }}</p>
                </div>
              </div>
              <div
               class="appointmentDetails">
                <div style="font-weight: bold">{{ $t("Customer.ViewPrescription.Allergy") }}:</div>
                <div class="valueClass">
                  {{ presc_object.data.allergies }}
                </div>
              </div>

              <div
                class="appointmentDetails">
                <div style="font-weight: bold">{{ $t("Customer.ViewPrescription.Diagnosis") }}:</div>
                <div>
                  {{ presc_object.data.diagnosed_with }}
                </div>
              </div>

              <div style="padding: 10px;">
                <div style="font-weight: bold; font-size: 14px;">{{ $t("Customer.ViewPrescription.Doctor_Prescription") }}</div>
                <div>
                  <div>
                    <table style="width:100%; table-layout: fixed; font-size: 12px;">
                      <tr>
                        <td  style="width:25%; word-wrap:break-word; border:   solid 1px #000000; padding: 5px;font-weight:bold">{{ $t("Customer.ViewPrescription.Medicine") }}</td>
                        <td  style="width:25%; word-wrap:break-word; border:   solid 1px #000000; padding: 5px;font-weight:bold">{{ $t("Customer.ViewPrescription.Dosage") }}</td>
                        <td  style="width:25%; word-wrap:break-word; border:   solid 1px #000000; padding: 5px;font-weight:bold">{{ $t("Customer.ViewPrescription.Frequency") }}</td>
                        <td  style="width:25%; word-wrap:break-word; border:   solid 1px #000000; padding: 5px;font-weight:bold">{{ $t("Customer.ViewPrescription.Duration") }}</td>
                      </tr>
                      <tr  v-for="presc in presc_object.data.prescription" :key="presc.unit">
                        <td  style="width:25%; word-wrap:break-word; border:   solid 1px #000000; padding: 5px;">{{ presc.medicine }}</td>
                        <td  style="width:25%; word-wrap:break-word; border:   solid 1px #000000; padding: 5px;">{{ presc.dosage }}</td>
                        <td  style="width:25%; word-wrap:break-word; border:   solid 1px #000000; padding: 5px;">{{ presc.frequency }}</td>
                        <td  style="width:25%; word-wrap:break-word; border:   solid 1px #000000; padding: 5px;">{{ presc.duration }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <table style="text-align:left">
                <tr>
                  <td style="padding-left:2%;font-weight:bold">
                    {{ $t("Customer.ViewPrescription.Suggestion") }}
                  </td>
                  <td> :</td>
                  <td style="padding-left:2%;">    {{ presc_object.data.suggestion }}</td>
                </tr>
                <tr>
                  <td style="padding-left:2%;font-weight:bold">
                      {{ $t("Customer.ViewPrescription.Investigation") }}
                  </td>
                  <td> :</td>
                  <td style="padding-left:2%">     {{ presc_object.data.investigationNeeded }}</td>
                </tr>
                <tr>
                  <td style="padding-left:2%;font-weight:bold">
                      {{ $t("Customer.ViewPrescription.Follow_Up") }}
                  </td>
                  <td> :</td>
                  <td style="padding-left:2%">    {{ presc_object.data.next_followup_date }}</td>
                </tr>
                 <tr>
                  <td style="padding-left:2%;font-weight:bold">
                     {{ $t("Customer.ViewPrescription.Referred_to") }}
                  </td>
                  <td> :</td>
                  <td style="padding-left:2%">     {{ presc_object.data.referredSpecialistName }}</td>
                </tr>
              </table>
                  <!-- <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  padding: 10px 10px;
                ">
                <div align="left" style="font-weight: bold">
                  {{ $t("Customer.ViewPrescription.Suggestion") }}
                </div>
                <div style="text-align: justify; margin-left:15px">
                  {{ presc_object.data.suggestion }}
                </div>
              </div> -->
              <!-- <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  padding: 10px 10px;
                ">
                <div align="left" style="font-weight: bold">
                  {{ $t("Customer.ViewPrescription.Investigation") }}
                </div>
                <div>
                  {{ presc_object.data.investigationNeeded }}
                </div>
              </div> -->

              <!-- <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  padding: 10px 10px;
                ">
                <div style="font-weight: bold">{{ $t("Customer.ViewPrescription.Follow_Up") }}</div>
                <div>
                  {{ presc_object.data.next_followup_date }}
                </div>
              </div> -->
                <!-- <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  padding: 10px 10px;
                ">
                <div style="font-weight: bold">{{ $t("Customer.ViewPrescription.Referred_to") }}</div>
                <div>
                  {{ presc_object.data.referredSpecialistName }}
                </div>
              </div> -->
                <div style="border-bottom:   double 1px #000000;"></div>
                <div style="border-bottom:   double 3px #000000;"></div>
                <div class="doctorDetails">
                  <div>
                     <h5 class="doctorValues">{{ presc_object.data.doctor_name }}</h5>
                     <h5 class="doctorValues">{{ presc_object.data.specialization }}</h5>
                         <!-- <h5 class="doctorValues">{{ presc_object.data.doctor_name }}</h5> -->
                  </div>
                </div>
            </div>
          </div>

        </v-card>
        <div style="margin-top: 10%" v-else>
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </v-container>
    </v-app>
  </div>
</template>
<script>
import NavBar from "../../../views/navbar.vue";
import axios from "axios";
import bottomnavbar from "./bottomnavbar.vue";
export default {
  name: "CustomerHome",
  data() {
    return {
      customer_id: "",
      currentCustomer: "",
      customer_name: "",
      appointment_reference_number: "",
      presc_object: "",
      auth_error_enable: false,
      isMobile: false,
      isLoadingPresp: true,
      show_header: true,
      ageOfPatient:"",
      allAttachments:""
    };
  },
  components: {
    NavBar,
    bottomnavbar,
  },
  mounted() {
    var userAgent = navigator.userAgent
    if(userAgent.includes("kbzpay")){
      this.show_header = false;
    }
    this.isMobile = this.$device.mobile;
    // document.title = "View Prescription"
    if(this.$store.state.locale == "en")
      document.title = "View Prescription"
    else
      document.title = "ဆေးစာကြည့်ရှုခြင်း"
    if(this.$store.state.locale !== "")
    {
      this.$i18n.locale =  this.$store.state.locale;
    }
    else
    {
      this.$i18n.locale = "mm";
      this.$store.dispatch("changeLocale", this.$i18n.locale);
    }
    this.currentCustomer = this.$cookies.get("customerToken");
    var access = this.$cookies.get("access");
    if (!this.currentCustomer) {
      this.$router.push({
        name: "Login",
      });
    } else {
        // var verifyCustomerTokenData = {
        //   token: this.currentCustomer,
        //   access: access,
        //   typeOfUser: "CUSTOMER",
        // };
        // axios
        //   .post(
        //     process.env.VUE_APP_BACKEND_URL + "/verifyCustomerToken",
        //     verifyCustomerTokenData
        //   )
        //   .then((verifyCustomerTokenDataResponse) => {
        //     this.customer_name =
        //       verifyCustomerTokenDataResponse.data.data.customer_name;
            var params = this.$route.params;
            this.appointment_reference_number =
              params["appointment_reference_number"];
            this.booking_details = this.getPrescriptionDetails(
              this.appointment_reference_number
            );
            //
            // console.log("Age", verifyCustomerTokenDataResponse.customer_data.customer_dob)
          // })
          // .catch((verifyCustomerTokenDataError) => {
          //   if (verifyCustomerTokenDataError.response.status == 401) {
          //     this.auth_error_enable = true;
          //   } else {
          //     this.error_enable = true;
          //   }
          // });
    }
  },
  methods: {
    ViewFile(filename)
    {
      var name_arr = filename.split("/");
      var name = name_arr[name_arr.length -1];
      var read_body = {
        file_name: name,
        token : this.currentCustomer,
        typeOfUser : "CUSTOMER",
      };
      axios.post(process.env.VUE_APP_BACKEND_URL+"/getPresignedURLRead",read_body)
      .then((readSuccess) =>{
        window.open(readSuccess.data.data,'_blank');
      })
      .catch((readError) =>{
        console.log(readError);
        window.alert("Error while reading the file");
      });
    },
    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    closeDialogUnauthorized() {
      this.auth_error_enable = false;
      this.$router.push({
        name: "Login",
      });
    },
    getPrescriptionDetails(appointment_reference_number) {
      var presc_body = {
        appointmentReferenceNumber: appointment_reference_number,
        token: this.currentCustomer,
        typeOfUser: "CUSTOMER",
      };
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/readAppointmentPrescription",
          presc_body
        )
        .then((getPrescriptionDetailsResponse) => {
          this.presc_object = getPrescriptionDetailsResponse.data;
          this.isLoadingPresp = false;
          // console.log( getPrescriptionDetailsResponse.data.customer_data);
          this.allAttachments = this.presc_object.appointment_data.file_attachment;
          this.ageOfPatient = this.getAge( getPrescriptionDetailsResponse.data.customer_data.customer_dob);
          for (let prescription_record of this.presc_object.data.prescription) {
            let temp_var = '';
            switch (prescription_record.frequency.toUpperCase().trim()) {
              case 'OD':
                temp_var = this.$i18n.locale == 'en' ? '1 time per day' : 'တစ်နေ့ တစ်ကြိမ်';
                break;
              case 'CM':
                temp_var = this.$i18n.locale == 'en' ? '1 time per day (Morning)' : 'မနက် တစ်ကြိမ်';
                break;
              case 'HS':
                temp_var = this.$i18n.locale == 'en' ? '1 time per day (Bed time)' : 'ညမအိပ်ခင် တစ်ကြိမ်';
                break;
              case 'BD':
                temp_var = this.$i18n.locale == 'en' ? '2 times per day' : 'မနက်တစ်ကြိမ်၊ ညနေတစ်ကြိမ်';
                break;
              case 'TDS':
                temp_var = this.$i18n.locale == 'en' ? '3 times per day' : 'မနက်တစ်ကြိမ်၊ နေ့လည်တစ်ကြိမ်၊ ညတစ်ကြိမ်';
                break;
              case 'QID':
                temp_var = this.$i18n.locale == 'en' ? '4 times per day' : 'မနက်တစ်ကြိမ်၊ နေ့လည်တစ်ကြိမ်၊ ညနေတစ်ကြိမ်၊ ညတစ်ကြိမ်';
                break;
              case 'PRN':
                temp_var = this.$i18n.locale == 'en' ? 'As require as needed' : 'လိုအပ်သလို သောက်ပါ';
                break;
              default:
                temp_var = prescription_record.frequency;
                break;
            };
            prescription_record.frequency = temp_var;
          }
          this.userPageViewTable();
        })
        .catch((getPrescriptionDetailsError) => {
          console.log(getPrescriptionDetailsError);
          if (getPrescriptionDetailsError.response.status == 401) {
            this.isLoadingPresp = false;
            this.auth_error_enable = true;
          } else {
            this.isLoadingPresp = false;
            this.error_enable = true;
          }
        });
    },
    redirectToHomePage() {
      this.$router.go(-1);
    },
    userPageViewTable() {
            if (this.currentCustomer === null) {
                var userPageViewTableBody = {
                pageName : 'viewPrescription',
                typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                pageName: 'viewPrescription',
                typeOfUser: 'CUSTOMER',
                token: this.currentCustomer
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
    },
  },
};
</script>
<style>
.prescriptionHeader{
display: flex;
flex-direction: row
}
.headerTextClass{
  font-size: 12px;
  padding: 0px !important;
  margin: 0px !important;
 text-align: justify;
}
.appointmentDetails{
  display: flex;
  flex-direction: row;
  padding: 3px 10px;
  font-size: 12px;
}
.ageGenderClass{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 5%;
  margin-bottom: 5%;
  padding-left: 2%;
  font-size: 12px;
}
.detailsClass{
  display: flex;
  flex-direction: column;
  font-size: 12px;
}
.valueClass{
  padding-left:8px
}
.detailsTitle{
  font-weight: bold;
  text-align:left;
   padding: 3px 10px;
   margin-bottom: 2px !important;
}
.attachmentClass{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.detailsValue{
  text-align: left;
   padding: 3px 10px;
   margin-bottom: 0px !important;
}
.doctorDetails{
  display:flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;

}
.doctorValues{
  margin-right:10px;
}
table {
  font-size: 12px;
}
/* @media (min-width: 800px){
  .prescriptionClass{
   width: 800px !important;
   margin-left: auto !important ;
    margin-right: auto !important ;

  }} */
</style>